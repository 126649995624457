// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---src-pages-404-js": () => import("./../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-i-18-n-redirect-js": () => import("./../src/i18n/redirect.js" /* webpackChunkName: "component---src-i-18-n-redirect-js" */),
  "component---src-pages-about-anvita-js": () => import("./../src/pages/about-anvita.js" /* webpackChunkName: "component---src-pages-about-anvita-js" */),
  "component---src-pages-about-kathak-js": () => import("./../src/pages/about-kathak.js" /* webpackChunkName: "component---src-pages-about-kathak-js" */),
  "component---src-pages-about-sharmila-sharma-js": () => import("./../src/pages/about-sharmila-sharma.js" /* webpackChunkName: "component---src-pages-about-sharmila-sharma-js" */),
  "component---src-pages-about-shovana-narayan-js": () => import("./../src/pages/about-shovana-narayan.js" /* webpackChunkName: "component---src-pages-about-shovana-narayan-js" */),
  "component---src-pages-baithak-js": () => import("./../src/pages/baithak.js" /* webpackChunkName: "component---src-pages-baithak-js" */),
  "component---src-pages-contact-js": () => import("./../src/pages/contact.js" /* webpackChunkName: "component---src-pages-contact-js" */),
  "component---src-pages-index-js": () => import("./../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-news-js": () => import("./../src/pages/news.js" /* webpackChunkName: "component---src-pages-news-js" */),
  "component---src-pages-performances-photos-js": () => import("./../src/pages/performances-photos.js" /* webpackChunkName: "component---src-pages-performances-photos-js" */),
  "component---src-pages-performances-videos-js": () => import("./../src/pages/performances-videos.js" /* webpackChunkName: "component---src-pages-performances-videos-js" */),
  "component---src-pages-serendipity-js": () => import("./../src/pages/serendipity.js" /* webpackChunkName: "component---src-pages-serendipity-js" */),
  "component---src-pages-success-js": () => import("./../src/pages/success.js" /* webpackChunkName: "component---src-pages-success-js" */),
  "component---src-pages-teaching-js": () => import("./../src/pages/teaching.js" /* webpackChunkName: "component---src-pages-teaching-js" */),
  "component---src-pages-women-speak-js": () => import("./../src/pages/women-speak.js" /* webpackChunkName: "component---src-pages-women-speak-js" */)
}

